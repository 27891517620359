<template>
  <el-input
    :placeholder="__('Please input password')"
    :value="value"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    show-password
  ></el-input>
</template>

<script>
export default {
  props: ["value"],
  methods: {
    onInput(val) {
      this.$emit("input", "my-input: " + val);
    }
  }
};
</script>
