import { FEATURE_FLAG_KEYS } from "./launch-darkly/featureFlagKeys";
import _ from "lodash";

// register feature flag constant keys in the plugin
const FeatureFlagConstants = {
  install(Vue) {
    Vue.prototype.$getConst = key => {
      return _.get(FEATURE_FLAG_KEYS, key);
    };
  }
};

export default FeatureFlagConstants;
