module.exports = {
  request: function(req, token) {
    this.options.http._setHeaders.call(this, req, {
      Authorization: "Bearer " + token
    });
  },

  response: function(res) {
    // Assume token refresh failed when response status is not 2xx and return response from logout
    if (
      res.config.url === this.options.refreshData.url &&
      (res.status < 200 || res.status > 299)
    ) {
      let redirectUrl = window.location.href
        .split(window.location.origin)
        .splice(-1, 1)
        .pop();
      return this.logout({
        makeRequest: false,
        redirect: { name: "Login", query: { redirectUrl } }
      });
    }

    let headers = this.options.http._getHeaders.call(this, res),
      token = headers.Authorization || headers.authorization;
    if (
      !res.data.pending_2fa_setup &&
      res.data.otp_required &&
      this.requireOtp
    ) {
      this.requireOtp(res.data.token);
      return false;
    } else {
      if (token) {
        // eslint-disable-next-line no-useless-escape
        token = token.split(/Bearer\:?\s?/i);

        return token[token.length > 1 ? 1 : 0].trim();
      }
    }
  }
};
