import Vue from "vue";
import App from "./App.vue";
import __ from "@/translation";

// attaching a listener to popstate event to identify whether
// a back button has been clicked
window.popStateDetected = false;
window.addEventListener("popstate", () => {
  window.popStateDetected = true;
});

import router from "./router";
/*import inference from "./router";
import fiv9 from "./router";*/
import "@iconfu/svg-inject";

import VueEcho from "vue-echo";
import echo from "./echo";
Vue.use(VueEcho, echo);

echo.connector.socket.on("subscription_error", (channel, data) => {
  console.error({ message: "unable to subscribe to channel", channel, data });
  echo.leave(channel);
});

import store from "./store";

import "normalize.css/normalize.css"; // a modern alternative to CSS resets

import "vue-json-pretty/lib/styles.css";

//font awesome setup
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fas);
Vue.component("font-awesome-icon", FontAwesomeIcon);

/* eslint-env browser */
if (window.localStorage.getItem("studioTranslation") !== null) {
  const newLang = JSON.parse(window.localStorage.getItem("studioTranslation"));
  window.StudioLang = newLang.lang;
  window.localStorage.setItem("appLang", newLang.code);
  window.localStorage.removeItem("studioTranslation");
} else {
  window.StudioLang = {};
  window.localStorage.setItem("appLang", "en");
}

window.__ = __;
Vue.prototype.__ = window.__;

// element-ui setup
import Element from "element-ui";
import "@/styles/index.scss"; // global cssv
import locale from "element-ui/lib/locale/lang/en"; // element-ui lang
import contenteditable from "vue-contenteditable";

Vue.use(contenteditable);

// i18n setup for libs
import { i18n } from "./i18n";

Vue.use(Element, {
  size: "medium", // set element-ui default size
  locale: locale,
  i18n: (key, value) => i18n.t(key, value)
});

import Clipboard from "v-clipboard";

Vue.use(Clipboard);

import vuescroll from "vue-scroll";

Vue.use(vuescroll);

import "./authentication"; // setting up authentication
import "./permission"; // permission control

import HighchartsVue from "highcharts-vue";

Vue.use(HighchartsVue);

import ElFormRenderer from "@femessage/el-form-renderer";
import InputPassword from "@/layout/base-contents/components/InputPassword";

Vue.use(ElFormRenderer);
Vue.component("input-password", InputPassword);

import "xe-utils";
import VXETable from "vxe-table";
import "vxe-table/lib/index.css";
Vue.use(VXETable, {
  i18n: (key, args) => i18n.t(key, args)
});

Vue.config.productionTip = false;

import "./canChecks";
import "./mixins/ldCheck";
import "./directives"; // register directives

Vue.directive("click", {
  bind: function(el, binding) {
    el.addEventListener("click", function(event) {
      binding.value(event);
    });
  }
});

import { isSessionTimeout } from "@/utils/sessionTimeout";
// Periodically check if the session has timed-out
setInterval(isSessionTimeout, 30 * 1000);
/*

const host = window.location.host;
const parts = host.split(".");
const domainLength = 2;
*/

/*let routers = () => {
  let routes;
  if (parts.length === domainLength - 1 || parts[0] === "localhost") {
    routes = router;
  } else if (parts[0] === "inference") {
    routes = inference;
  } else if (parts[0] === "fiv9") {
    routes = fiv9;
  } else {
    // If you want to do something else just comment the line below
    routes = router;
  }
  return routes;
};*/

// import { VueLd } from "vue-ld";
//
// Vue.use(VueLd, {
//   clientSideId: process.env.VUE_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID,
//   user: {
//     key: "ANONYMOUS_USER_KEY",
//     anonymous: true
//   },
//   // Standard LaunchDarkly JavaScript SDK options
//   options: {
//     /** Bootstrap from locally cached values.
//      * In this mode, if no values have been cached yet, the SDK obtains flags from LaunchDarkly and then caches them in persistent storage.
//      * The next time you start, the cached flags are immediately available,
//      * and the SDK also contacts LaunchDarkly in the background to obtain updated values.
//      */
//     bootstrap: "localStorage"
//   },
//   // If set to false, the $ld.ready will only be true after identify has been called.
//   readyBeforeIdentify: false
// });

// infinite scroll
import infiniteScroll from "vue-infinite-scroll";
Vue.use(infiniteScroll);

// Use any constants declared in this plugin globally throughout the app
import constantsPlugin from "./constants/constantPlugin";

Vue.use(constantsPlugin);

import moment from "moment-timezone";
moment.locale("en", {
  week: {
    // sets monday as the start of the week.
    // as backend Carbon uses the first day of the week as monday
    // needs to be consistent bw frontend and backend to compute week date range
    dow: 1
  }
});

let momentDurationFormat = require("moment-duration-format");
momentDurationFormat(moment);

window.moment = moment;

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
  created: function() {
    Vue.$ld = this.$ld;
  }
}).$mount("#app");
