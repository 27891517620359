import request from "@/utils/studio7ApiService";

/**
 * Get the corresponding translations for a given language code
 * @param {String} code Language code
 */
export function getLanguage(code) {
  return request({
    url: `lang/${code}`,
    method: "get"
  });
}
