<template>
  <div class="main" v-loading="isLoading" :key="appKey">
    <div class="app-main" v-if="$auth.ready()">
      <router-view v-if="finishLanguageCheck"></router-view>
      <GainSightPX v-if="isLoggedIn" />
    </div>

    <div v-else v-loading="true" style="height: 100vh;"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getLanguage } from "@/api/lang";
import { EventBus } from "@/EventBus";
import Translation from "@/mixins/translation";
import GainSightPX from "@/components/lib/GainSightPX.vue";
const version = require("element-ui/package.json").version;
const ORIGINAL_THEME = "#244CDE";

export default {
  name: "App",
  mixins: [Translation],
  components: { GainSightPX },
  props: {
    //default theme, by an external incoming
    v: { type: String, default: version },
    default: {
      type: String,
      default: localStorage.getItem("COLOR_THEME")
    },
    size: {
      type: String,
      default: "small"
    }
  },
  data() {
    return {
      chalk: "", //content of theme-chalk css
      theme: ORIGINAL_THEME,
      showSuccess: true, //whether to eject peels success message
      appKey: 0, // Increment this value to force rerender
      finishLanguageCheck: false
    };
  },
  computed: {
    ...mapState("theme", {
      theme_color: state => state.colors.theme_color,
      theme_hover_color: state => state.colors.hover_color
    }),
    ...mapState("app", {
      isLoading: state => state.isLoadingOptions
    }),
    isLoggedIn() {
      const user = this.$auth.user();
      return user && user.user_id !== undefined && user.user_id !== null;
    }
    // ...mapGetters("app", {
    //   selectedServiceProviderAccountCombination:
    //     "selectedServiceProviderAccountCombination"
    // })
  },
  mounted() {
    if (this.default != null) {
      this.theme = this.theme_color;
      this.$emit("onThemeChange", this.theme_color);
      //this. $store.commit (types.M_THEME_COLOR, this.theme)
      this.showSuccess = false;
    }

    // Hook to on language changed event
    this.handleInterfaceLangChange();

    // Attempt to change the language
    if (this.$auth.check()) {
      const user = this.$auth.user();
      if (user.language && user.language !== this.getAppLang()) {
        this.translateForUser(user.language);
      } else {
        this.finishLanguageCheck = true;
      }
    } else {
      this.finishLanguageCheck = true;
    }

    this.changeAppLocale(this.getAppLang());
  },
  watch: {
    theme_color(val, oldVal) {
      if (typeof val !== "string") return;
      const themeCluster = this.getThemeCluster(val.replace("#", ""));
      const originalCluster = this.getThemeCluster(oldVal.replace("#", ""));
      const getHandler = (variable, id) => {
        return () => {
          const originalCluster = this.getThemeCluster(
            ORIGINAL_THEME.replace("#", "")
          );
          const newStyle = this.updateStyle(
            this[variable],
            originalCluster,
            themeCluster
          );

          let styleTag = document.getElementById(id);
          if (!styleTag) {
            styleTag = document.createElement("style");
            styleTag.setAttribute("id", id);
            document.head.appendChild(styleTag);
          }
          styleTag.innerText = newStyle;
          let bodyStyles = document.querySelector("html").style;
          bodyStyles.setProperty("--theme-color", val);
          bodyStyles.setProperty("--dropdown-hover-color", val);
        };
      };

      const chalkHandler = getHandler("chalk", "chalk-style");

      if (!this.chalk) {
        const url = `https://unpkg.com/element-ui@${version}/lib/theme-chalk/index.css`;
        this.getCSSString(url, chalkHandler, "chalk");
      } else {
        chalkHandler();
      }

      const styles = [].slice
        .call(document.querySelectorAll("style"))
        .filter(style => {
          const text = style.innerText;
          return (
            new RegExp(oldVal, "i").test(text) && !/Chalk Variables/.test(text)
          );
        });
      styles.forEach(style => {
        const { innerText } = style;
        if (typeof innerText !== "string") return;
        style.innerText = this.updateStyle(
          innerText,
          originalCluster,
          themeCluster
        );
      });

      //response to external operation
      this.$emit("onThemeChange", val);
      //this. $store.commit (types.M_THEME_COLOR, val)

      if (this.showSuccess) {
        /*this.$message({
          message: "peels success",
          type: "success"
        });*/
      } else {
        this.showSuccess = true;
      }
    }
    //selectedServiceProviderAccountCombination: {
    // handler() {
    //this function has been deprecated
    //this.refreshFeatureFlags();
    //   }
    // }
  },
  methods: {
    updateStyle(style, oldCluster, newCluster) {
      let newStyle = style;
      console.log(newCluster);
      let bodyStyles = document.querySelector("html").style;
      bodyStyles.setProperty("--theme-hover-background-color", newCluster[10]);
      bodyStyles.setProperty("--theme-tag-border-color", newCluster[9]);
      bodyStyles.setProperty("--theme-hover-font-color", newCluster[3]);
      //following 2 are used in content-palette.scss
      bodyStyles.setProperty(
        "--customform-content-light-5",
        this.computeShade(0.5, this.theme_color)
        //this.getLighterShade(this.theme_color, 0.5)
      );
      bodyStyles.setProperty(
        "--color-primary-light-5",
        //this.getLighterShade(this.theme_color, 0.5)
        this.computeShade(0.5, this.theme_color)
      );
      bodyStyles.setProperty(
        "--integration-content-dark",
        // this.getLighterShade(this.theme_color, -0.1)
        this.computeShade(-0.1, this.theme_color)
      );
      bodyStyles.setProperty(
        "--color-primary-dark",
        // this.getLighterShade(this.theme_color, -0.1)
        this.computeShade(-0.1, this.theme_color)
      );
      bodyStyles.setProperty(
        "--theme-color-light-10p",
        this.computeShade(0.1, this.theme_color)
      );
      bodyStyles.setProperty(
        "--theme-color-light-40p",
        this.computeShade(0.4, this.theme_color)
      );
      oldCluster.forEach((color, index) => {
        newStyle = newStyle.replace(new RegExp(color, "ig"), newCluster[index]);
      });
      return newStyle;
    },

    getCSSString(url, callback, variable) {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          this[variable] = xhr.responseText.replace(/@ font-face {[^}] +}/, "");
          callback();
        }
      };
      xhr.open("GET", url);
      xhr.send();
    },

    getLighterShade(hex, lum) {
      // validate hex string
      hex = String(hex).replace(/[^0-9a-f]/gi, "");
      if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      lum = lum || 0;

      // convert to decimal and change luminosity
      var rgb = "#",
        c,
        i;
      for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
        rgb += ("00" + c).substr(c.length);
      }

      return rgb;
    },

    computeShade(p, c0, c1, l) {
      const pSBC = (p, c0, c1, l) => {
        let r,
          g,
          b,
          P,
          f,
          t,
          h,
          m = Math.round,
          a = typeof c1 == "string";
        if (
          typeof p != "number" ||
          p < -1 ||
          p > 1 ||
          typeof c0 != "string" ||
          (c0[0] !== "r" && c0[0] !== "#") ||
          (c1 && !a)
        )
          return null;
        (h = c0.length > 9),
          (h = a ? (c1.length > 9 ? true : c1 === "c" ? !h : false) : h),
          (f = pSBC.pSBCr(c0)),
          (P = p < 0),
          (t =
            c1 && c1 !== "c"
              ? pSBC.pSBCr(c1)
              : P
              ? { r: 0, g: 0, b: 0, a: -1 }
              : { r: 255, g: 255, b: 255, a: -1 }),
          (p = P ? p * -1 : p),
          (P = 1 - p);
        if (!f || !t) return null;
        if (l)
          (r = m(P * f.r + p * t.r)),
            (g = m(P * f.g + p * t.g)),
            (b = m(P * f.b + p * t.b));
        else
          (r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5)),
            (g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5)),
            (b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5));
        (a = f.a),
          (t = t.a),
          (f = a >= 0 || t >= 0),
          (a = f ? (a < 0 ? t : t < 0 ? a : a * P + t * p) : 0);
        if (h)
          return (
            "rgb" +
            (f ? "a(" : "(") +
            r +
            "," +
            g +
            "," +
            b +
            (f ? "," + m(a * 1000) / 1000 : "") +
            ")"
          );
        else
          return (
            "#" +
            (
              4294967296 +
              r * 16777216 +
              g * 65536 +
              b * 256 +
              (f ? m(a * 255) : 0)
            )
              .toString(16)
              .slice(1, f ? undefined : -2)
          );
      };

      pSBC.pSBCr = d => {
        const i = parseInt,
          m = Math.round;
        let n = d.length,
          x = {};
        if (n > 9) {
          const [r, g, b, a] = (d = d.split(","));
          n = d.length;
          if (n < 3 || n > 4) return null;
          (x.r = i(r[3] === "a" ? r.slice(5) : r.slice(4))),
            (x.g = i(g)),
            (x.b = i(b)),
            (x.a = a ? parseFloat(a) : -1);
        } else {
          if (n === 8 || n === 6 || n < 4) return null;
          if (n < 6)
            d =
              "#" +
              d[1] +
              d[1] +
              d[2] +
              d[2] +
              d[3] +
              d[3] +
              (n > 4 ? d[4] + d[4] : "");
          d = i(d.slice(1), 16);
          if (n === 9 || n === 5)
            (x.r = (d >> 24) & 255),
              (x.g = (d >> 16) & 255),
              (x.b = (d >> 8) & 255),
              (x.a = m((d & 255) / 0.255) / 1000);
          else
            (x.r = d >> 16),
              (x.g = (d >> 8) & 255),
              (x.b = d & 255),
              (x.a = -1);
        }
        return x;
      };

      return pSBC(p, c0, c1, l);
    },

    getThemeCluster(theme) {
      const tintColor = (color, tint) => {
        let red = parseInt(color.slice(0, 2), 16);
        let green = parseInt(color.slice(2, 4), 16);
        let blue = parseInt(color.slice(4, 6), 16);

        if (tint === 0) {
          return [red, green, blue].join(",");
        } else {
          red += Math.round(tint * (255 - red));
          green += Math.round(tint * (255 - green));
          blue += Math.round(tint * (255 - blue));

          red = red.toString(16);
          green = green.toString(16);
          blue = blue.toString(16);

          return `#${red}${green}${blue}`;
        }
      };

      const shadeColor = (color, shade) => {
        // eslint-disable-next-line no-unused-vars
        let red = parseInt(color.slice(0, 2), 16);
        // eslint-disable-next-line no-unused-vars
        let green = parseInt(color.slice(2, 4), 16);
        // eslint-disable-next-line no-unused-vars
        let blue = parseInt(color.slice(4, 6), 16);

        red = Math.round((1 - shade) * red);
        green = Math.round((1 - shade) * green);
        blue = Math.round((1 - shade) * blue);

        red = red.toString(16);
        green = green.toString(16);
        blue = blue.toString(16);

        return `#${red}${green}${blue}`;
      };

      const clusters = [theme];
      for (let i = 0; i <= 9; i++) {
        clusters.push(tintColor(theme, Number((i / 10).toFixed(2))));
      }
      clusters.push(shadeColor(theme, 0.1));
      return clusters;
    },

    /**
     * Load new translation file
     * @param {String} code Language code
     * @param {Object} lang Translation object
     */
    loadTranslationObject: function(code, lang) {
      window.localStorage.setItem(
        "studioTranslation",
        JSON.stringify({ code, lang })
      );
      window.location.reload();
    },

    /**
     * A function that handles interface rerender if interface language is changed
     */
    handleInterfaceLangChange: function() {
      const that = this;
      EventBus.$on(Translation.TRANSLATE_EVENT_NAME, newVal => {
        if (newVal !== that.getAppLang()) {
          this.finishLanguageCheck = false;
          getLanguage(newVal)
            .then(resp => {
              this.loadTranslationObject(newVal, resp.data);
              this.finishLanguageCheck = true;
            })
            .catch(() => {
              this.loadTranslationObject(newVal, {});
              this.finishLanguageCheck = true;
            });
        }
      });
    }
  }
};
</script>

<style lang="scss">
:root {
  --theme-color: #244cde;
  --theme-hover-color: #0e97d4;
  --theme-row-hover-color: #eef1fc;
  --theme-outline-color: #9cd6fc;

  --site-secondary-background-color: #f5f5f8; // not affected by theme change
  --site-default-font-color: #454545; // not affected by theme change
  --site-secondary-background-hover-color: #eeeeee;
  --site-secondary-font-hover-color: #555555;
  --site-secondary-background-disabled-color: #f4f5f6;
  --site-secondary-font-disabled-color: #acadae;

  --site-deleteBtn-background-color: #eb0000;
  --site-deleteBtn-font-color: #ffffff;
  --site-deleteBtn-background-hover-color: #b20000;
  --site-deleteBtn-font-hover-color: #ffffff;
  --site-deleteBtn-background-disabled-color: #f08788;
  --site-deleteBtn-font-disabled-color: #f9fafb;
}
#app {
  /*font-family: "Avenir", Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.main {
  height: 100%;
}

.app-main {
  height: 100%;
}

.el-select-dropdown__wrap.el-scrollbar__wrap {
  margin: 0 -15px 0 0;
}

.globalContentItemModal {
  .el-dialog__body {
    min-height: 60vh;

    .section {
      height: 90vh;
    }
  }
}

.expressionBuilderModal {
  padding: 0 !important;
  .el-dialog__header {
    display: none !important;
  }
  .el-dialog__body {
    padding: 0 !important;
  }
}

.theme-picker .el-color-picker__trigger {
  vertical-align: middle;
}

.theme-picker-dropdown .el-color-dropdown__link-btn {
  display: none;
}

.el-tooltip__popper.is-studio-theme {
  background: var(--theme-color);
  color: white;
}

/* tooltip arrow */
.el-tooltip__popper.is-studio-theme .popper__arrow::after {
  border-top-color: var(--theme-color) !important;
}
</style>
